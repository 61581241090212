import {PRODUCT_TYPES} from 'components/customer/Product/helpers/mapInitialValues';
import {cloneDeep} from 'lodash';
import {toFixed, validSubpanels} from 'shared/helpers';

const DOOR_HANG_TYPE = {
    PAIR: 2,
};

export const mapProductData = ({
    values,
    productDataStore,
    shelvesStructureStore,
    shelfTypes,
    shelfStyles,
}) => {
    values.cabinet_style = productDataStore.current.cabinet.attributes.style; //Set the type of Cabinet
    values.cabinet_name = productDataStore.current.cabinet.attributes.original_name;; //Set the name of Cabinet

    if (
        productDataStore.current.cabinet_details &&
        productDataStore.current.cabinet_details.cabinet_tops
    ) {
        const top = productDataStore.current.cabinet_details.cabinet_tops.find(
            (top) => values.cabinet_top == top.id
        );

        values.cabinet_top_details = typeof top === 'undefined' ? 'None' : top;
    }
    if (
        productDataStore.current.doors &&
        productDataStore.current.doors.length
    ) {
        const doors = cloneDeep(productDataStore.current.doors);

        values.doors = doors; // set door values from formik values

        values.doors = values.doors.map((door) => {
            door.door_hang_type = values.door_hang_type;
            door.horizontal_positions = values.hori_mid_rail_positions;
            door.vertical_positions = values.vert_mid_rail_positions;
            return door;
        });

        if (values.glassSubPanels) {
            let subPanelCount = 1;
            const updatedSubPanels = validSubpanels(values.glassSubPanels);

            updatedSubPanels.forEach((subPanels) => {
                subPanels.forEach((subPanel) => {
                    values[`sub_panel_${subPanelCount}`] = subPanel;

                    subPanelCount++;
                });
            });
        }
    }

    if (values.hasOwnProperty('rail_hori') && values.rail_hori == 0)
        values.rail_hori = [];

    if (values.hasOwnProperty('rail_vert') && values.rail_vert == 0)
        values.rail_vert = [];

    // door_hang_type check this and cabinetStyle = 5 and set hinge amount equal to drilling. incase of pair hinge amount * 2.
    if (
        productDataStore.current.hasOwnProperty('cabinet') &&
        productDataStore.current.cabinet.hasOwnProperty('attributes') &&
        productDataStore.current.cabinet.attributes.style ===
            PRODUCT_TYPES.COMPONENTS &&
        values.hasOwnProperty('drillings') &&
        values.hasOwnProperty('door_hang_type')
    ) {
        values.hinge = values.drillings.length;

        if (values.door_hang_type === DOOR_HANG_TYPE.PAIR) {
            values.hinge = 2 * values.drillings.length;
        }
    }

    if (
        values.hasOwnProperty('drawer_runner_specs') &&
        values.hasOwnProperty('drawer_type')
    ) {
        values.drawer_runner_specs = [values.drawer_runner_specs];
        values.drawer_type = [values.drawer_type];
        values.drawer_face_height = [-1];
        values.drawer_style = [-1];
    }

    if (values.hasOwnProperty('drawers')) {
        values.drawer_amount = values.drawers.length;
        values.drawer_face_height = values.drawers.map(
            (drawer) => drawer.drawer_face_height
        );
        values.drawer_runner_specs = values.drawers.map(
            (drawer) => drawer.drawer_runner_specs
        );
        values.drawer_style = values.drawers.map(
            (drawer) => drawer.drawer_style
        );
        values.drawer_type = values.drawers.map((drawer) => drawer.drawer_type);
    }

    if (
        values.hasOwnProperty('cabinet_upper_partition_height') &&
        parseFloat(values.cabinet_upper_partition_height) <= 1
    ) {
        values.cabinet_upper_partition_height = `${
            parseFloat(values.cabinet_upper_partition_height) * 100
        }%`;
    }

    if (
        values.hasOwnProperty('cabinet_lower_partition_height') &&
        parseFloat(values.cabinet_lower_partition_height) <= 1
    ) {
        values.cabinet_lower_partition_height = `${
            parseFloat(values.cabinet_lower_partition_height) * 100
        }%`;
    }

    if (values.hasOwnProperty('cabinet_edge_L1')) {
        values.cabinet_edge_l1 = values.cabinet_edge_L1;
    }

    if (values.hasOwnProperty('cabinet_edge_L2')) {
        values.cabinet_edge_l2 = values.cabinet_edge_L2;
    }

    if (values.hasOwnProperty('cabinet_edge_W1')) {
        values.cabinet_edge_w1 = values.cabinet_edge_W1;
    }

    if (values.hasOwnProperty('cabinet_edge_W2')) {
        values.cabinet_edge_w2 = values.cabinet_edge_W2;
    }

    //shelves
    Object.keys(shelvesStructureStore.current).forEach((fieldset) => {
        const shelfStructure = shelvesStructureStore.current[fieldset];
        let shelves = values.hasOwnProperty(fieldset) && values[fieldset];
        let shelfStyle;

        if (fieldset === 'shelves') {
            if (
                values.hasOwnProperty('cabinet_simple_shelves') &&
                values.cabinet_simple_shelves
            ) {
                shelfStyle =
                    values.hasOwnProperty('simple_shelf_type') &&
                    values.simple_shelf_type;

                if (values.simple_shelves_amount != shelves.length) {
                    shelves = [];
                    values.shelves = [];
                }
            }
        }

        if (shelves && Array.isArray(shelves) && shelves.length) {
            shelfStructure.forEach((field) => {
                values[field] = [];

                for (let i = 0; i < shelves.length; i++) {
                    if (field.indexOf('position') > -1) {
                        values[field].push(shelves[i][field]);
                    } else if (
                        field.indexOf('shelf_style') > -1 &&
                        typeof shelfStyle === 'number'
                    ) {
                        values[field].push(shelfStyle);
                    } else {
                        values[field].push(shelves[i][field]);
                    }
                }
            });
        } else {
            let shelvesCount = 0;
            const shelfType =
                shelfTypes.current.hasOwnProperty(fieldset) &&
                shelfTypes.current[fieldset];
            let shelfStyle =
                shelfStyles.current.hasOwnProperty(fieldset) &&
                shelfStyles.current[fieldset];

            switch (fieldset) {
                case 'shelves':
                    shelvesCount = values.hasOwnProperty(
                        'simple_shelves_amount'
                    )
                        ? values.simple_shelves_amount
                        : 0;
                    shelfStyle =
                        values.hasOwnProperty('simple_shelf_type') &&
                        values.simple_shelf_type;

                    if (typeof shelfStyle == 'boolean' && !shelfStyle) {
                        shelfStyle =
                            productDataStore.current.cabinet.attributes.hasOwnProperty(
                                'shelf_type'
                            ) &&
                            productDataStore.current.cabinet.attributes
                                .shelf_type;
                    }
                    break;
            }

            shelfStructure.forEach((field) => {
                values[field] = [];

                for (let i = 0; i < shelvesCount; i++) {
                    if (field.indexOf('type') >= 0) {
                        values[field].push(shelfType);
                    }

                    if (field.indexOf('style') >= 0) {
                        values[field].push(shelfStyle);
                    }

                    if (field.indexOf('position') >= 0) {
                        values[field].push(
                            toFixed((i + 1) / (shelvesCount + 1), 4)
                        );
                    }

                    if (field.indexOf('offset') >= 0) {
                        values[field].push(
                            values.shelf_set_back
                                ? parseFloat(values.shelf_set_back)
                                : 5
                        );
                    }
                }
            });
        }
    });
    //Shelves

    //drillings
    if (values.hasOwnProperty('drillings')) {
        if (
            productDataStore.current.hasOwnProperty('cabinet') &&
            productDataStore.current.cabinet.hasOwnProperty('attributes') &&
            productDataStore.current.cabinet.attributes.hasOwnProperty(
                'product_type'
            ) &&
            productDataStore.current.cabinet.attributes.product_type == 5
        ) {
            values.drilling_offset_x = values.drillings.map(
                (drilling) => drilling.drilling_offset_x
            );
            values.drilling_offset_y = values.drillings.map(
                (drilling) => drilling.drilling_offset_y
            );
            values.drilling_num_holes = values.drillings.map(
                (drilling) => drilling.drilling_num_holes
            );
            values.drilling_pitch = values.drillings.map(
                (drilling) => drilling.drilling_pitch
            );
        } else {
            values.hinge = values.drillings.map((drilling) => {
                return {
                    offset_x: drilling['drilling_offset_x'],
                    offset_y: drilling['drilling_offset_y'],
                    num_holes: drilling['drilling_num_holes'],
                    pitch: drilling['drilling_pitch'],
                };
            });
        }
    }

    //drillings

    if (values.hasOwnProperty('cabinet_left_depth')) {
        values.cabinet_width_depth = values.cabinet_left_depth;
    }

    if (values.hasOwnProperty('cabinet_right_depth')) {
        values.cabinet_length_depth = values.cabinet_right_depth;
    }

    return values;
};
